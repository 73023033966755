import React from 'react';
import DangerouslySetInnerHtml from 'components/DangerouslySetInnerHtml';

export type ContentProps = {
  content: string;
  className?: string;
};

const Content = ({ content, className }: ContentProps) => (
  <DangerouslySetInnerHtml html={content} className={className} />
);

export default Content;
