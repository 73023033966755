import React from 'react';
import DangerouslySetInnerHtml from 'components/DangerouslySetInnerHtml';

import { Person } from 'types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

type MemberProps = Person & {
  index: number;
  onClick: (id: number) => void;
};

const Member = ({ name, image, index, profesion, onClick }: MemberProps) => {
  const img = getImage(image);
  const baseDelay = 100 * index;

  return (
    <div
      className="team__member"
      data-sal="zoom-out"
      data-sal-duration={600}
      data-sal-delay={baseDelay}
      role="button"
      onClick={() => onClick(index)}
    >
      {img ? (
        <div className="team__member-image-wrapper">
          <GatsbyImage
            image={img}
            alt={`${name} - zdjęcie`}
            data-sal="fade"
            data-sal-duration={600}
          />
        </div>
      ) : null}
      {name ? <DangerouslySetInnerHtml className="team__member-title" html={name} /> : null}
      {profesion ? <p className="team__member-profesion">{profesion}</p> : null}
    </div>
  );
};

export default Member;
