import React from 'react';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import DangerouslySetInnerHtml from 'components/DangerouslySetInnerHtml';
import { Section } from 'types';

import './PageSection.scss';

type PageSectionProps = Section;

const PageSection = ({ title, text, image }: PageSectionProps) => {
  const img = getImage(image.image) as IGatsbyImageData;

  return (
    <div className="page-section">
      <div
        className="page-section__image"
        data-sal-duration={600}
        data-sal="fade"
        data-sal-easing="ease"
      >
        <div className="page-section__image-inner">
          <GatsbyImage image={img} alt={image.alt} />
        </div>
      </div>
      <div className="page-section__content">
        <h3
          className="page-section__title"
          data-sal-duration={600}
          data-sal="slide-left"
          data-sal-easing="ease"
          data-sal-delay="300"
        >
          {title}
        </h3>
        <DangerouslySetInnerHtml
          className="page-section__text"
          html={text}
          data-sal-duration={600}
          data-sal="slide-left"
          data-sal-easing="ease"
          data-sal-delay="500"
        />
      </div>
    </div>
  );
};

export default PageSection;
