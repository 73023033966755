import { getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import Content from 'components/Content';
import { ContentProps } from 'components/Content/Content';
import { Section } from 'types';
import PageSection from 'components/PageSection';
import Quote from 'components/Quote';
import { Team as TeamType } from 'types';
import Team from 'components/Team';
import SectionIntro from 'components/SectionIntro';
import FullscreenHero from 'components/FullscreenHero';
import PageHeader from 'components/PageHeader';

type AboutPageProps = {
  title: string;
  description: string;
  content: string;
  contentComponent?: (props: ContentProps) => React.ReactElement;
  image: IGatsbyImageData;
  sections: Section[];
  quotes: string[];
  team: TeamType;
};

const AboutPageTemplate = ({
  title,
  description,
  image,
  content,
  contentComponent,
  sections,
  quotes,
  team,
}: AboutPageProps) => {
  const PageContent = contentComponent || Content;
  const heroImage = getImage(image) || image;

  return (
    <div>
      <FullscreenHero image={heroImage} variant="paralax">
        <PageHeader title={title} text={description} />
      </FullscreenHero>
      <div className="page-wrapper">
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1 page-section-wrapper">
              {sections.map((props) => (
                <PageSection key={props.id} {...props} />
              ))}
              <Quote text={quotes[0]} />
              {sections.map((props) => (
                <PageSection key={props.id} {...props} />
              ))}
              <Quote variant="dark" text={quotes[1]} />
            </div>
          </div>
        </div>

        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1 page-section-wrapper">
              <SectionIntro title={team.title} subtitle={team.subtitle} />
            </div>
          </div>
        </div>
        <Team {...team} />

        {/* <section className="section">
          <div className="container">
            <div className="columns">
              <div className="column is-8 is-offset-2">
                <PageContent className="content" content={content} />
              </div>
            </div>
          </div>
        </section> */}
      </div>
    </div>
  );
};

export default AboutPageTemplate;
