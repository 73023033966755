import React from 'react';
import DangerouslySetInnerHtml from 'components/DangerouslySetInnerHtml';

import './Quote.scss';

type QuoteProps = {
  text: string;
  variant?: 'light' | 'dark';
};

const Quote = ({ text, variant = 'light' }: QuoteProps) => (
  <div
    className={`quote quote--${variant}`}
    data-sal="slide-up"
    data-sal-duration={600}
    data-sal-delay={300}
  >
    <DangerouslySetInnerHtml
      html={text}
      data-sal="slide-up"
      data-sal-duration={600}
      data-sal-delay={variant === 'light' ? 0 : 500}
    />
  </div>
);

export default Quote;
