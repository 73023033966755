import React, { useState } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Modal from 'components/Modal';
import Member from './Member';
import { Team as TeamType, Person } from 'types';
import './Team.scss';
import SectionIntro from 'components/SectionIntro';

type TeamProps = Omit<TeamType, 'title' | 'subtitle'>;

const Team = ({ members }: TeamProps) => {
  const [activeMember, setActiveMember] = useState<null | Person>(null);
  const { name, email, phone, description, profesion, image } = activeMember || {};
  const isModalActive = !!activeMember;
  const img = getImage(image!);

  const subtitle = `
    <strong>${profesion?.toUpperCase()}</strong><br>
    ${description}
  `;

  const onMemberClick = (index: number) => {
    setActiveMember(members[index]);
  };

  const resetActiveMember = () => {
    setActiveMember(null);
  };

  return (
    <div className="team">
      <div className="team__members">
        {members.map((member, index) => (
          <Member index={index} key={member.id} onClick={onMemberClick} {...member} />
        ))}
      </div>
      <Modal isActive={isModalActive} onClose={resetActiveMember}>
        <div className="container">
          {img ? (
            <GatsbyImage
              className="team__modal-image"
              image={img}
              alt={`${name} - zdjęcie`}
              data-sal="fade"
              data-sal-duration={600}
            />
          ) : null}
          <div className="columns">
            <div className="column is-6 is-offset-5 page-section-wrapper">
              <SectionIntro title={name} subtitle={subtitle} skipAnimation />
              {email ? (
                <p>
                  <span>email: </span>
                  <a href={`mailto: ${email}`}>{email}</a>
                </p>
              ) : null}
              {phone ? (
                <p>
                  <span>telefon: </span>
                  <a href={`tel: ${phone}`}>{phone}</a>
                </p>
              ) : null}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Team;
