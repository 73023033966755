import DangerouslySetInnerHtml from 'components/DangerouslySetInnerHtml';
import React, { FC } from 'react';

import './PageHeader.scss';

type PageHeaderProps = {
  title: string;
  text: string;
};

const PageHeader: FC<PageHeaderProps> = ({ title, text }) => (
  <div className="page-header">
    <h1
      className="page-header__title"
      data-sal="slide-up"
      data-sal-duration={600}
      data-sal-delay={200}
    >
      {title}
    </h1>
    <div
      className="page-header__text"
      data-sal="slide-up"
      data-sal-duration={600}
      data-sal-delay={400}
    >
      <DangerouslySetInnerHtml html={text} />
    </div>
  </div>
);

export default PageHeader;
