import React, { FC, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

import './Modal.scss';

type ModalProps = {
  children: React.ReactNode;
  isActive: boolean;
  onClose: () => void;
};

const Modal: FC<ModalProps> = ({ children, isActive, onClose }) => {
  const close = () => {
    onClose();
  };

  const handleEscape = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      close();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleEscape);

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, []);

  useEffect(() => {
    const bodyClass = 'modal-active';
    const method = isActive ? 'add' : 'remove';
    document.body.classList[method](bodyClass);
    document.documentElement.classList[method](bodyClass);
  }, [isActive]);

  return (
    <div className={`modal ${isActive ? 'modal--is-active' : ''}`}>
      <button className="button is-large modal__close" onClick={close}>
        <span className="icon is-small">
          <FontAwesomeIcon icon={faXmark} />
        </span>
      </button>
      <div className="modal__content">{isActive ? children : null}</div>
    </div>
  );
};

export default Modal;
